/* 
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: 서비스안내
┣ @작성: 신우균, 2021-01-20                        
┣ @내역: 신우균, 2021-01-20, 최초등록
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
<template>
    <div class="content">
        <div class="content-head">
            <h1>서비스안내</h1><hr>
        </div>
        <div class="content-body">
            <h2>이용대상 및 가입안내</h2>
            <table id="table-01" class="content-table content-table--vertical">
                <tbody>
                    <tr>
                        <td width="30%">이용대상</td>
                        <td width="70%">
                            <li>만 15세 이상의 남녀노소</li>
                            <!-- <li>단, 19세 미만은 부모님(법정대리인)의 동의가 필요합니다.</li> -->
                        </td>
                    </tr>
                    <tr>
                        <td>운영시간</td>
                        <td>
                            <li>뉴어울링: 24:00 운영</li>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="content-body">
            <h2>회원구분</h2>
            <table id="table-02" class="content-table content-table--vertical">
                <tbody>
                    <tr>
                        <td width="30%">정(유료)회원</td>
                        <td width="70%"><li>앱을 통하여 7일권 이상의 정회원 서비스를 가입한 유료 결제 회원</li></td>
                    </tr>
                    <tr>
                        <td>준(일반)회원</td>
                        <td>
                            <li>앱을 통하여 회원 가입은 하였으나 정회원 서비스를 가입 하지 않은 회원</li>
                            <!-- <li class="list-style-none">
                                <span class="badge alert-danger">Tip</span>
                                <strong> 모든 서비스는 비회원과 동일하게 적용됨</strong>
                            </li> -->
                        </td>
                    </tr>
                    <!-- <tr>
                        <td>비회원</td>
                        <td><li>회원 가입하지 않은 1일 이용권 구매자</li></td>
                    </tr> -->
                </tbody>
            </table>
        </div>
        <div class="content-body">
            <h2 class="mb-3">요금 안내 및 결제 방법</h2>
            <ul>
                <li>기본 사용료는 90분을 기준으로 합니다.</li>
                <li>
                    기본 사용료의 사용시간 범위에서 반복이용은 가능합니다.<br>
                    <span class="badge alert-danger">Tip</span>
                    <strong> 별도의 사용료는 부과하지 않음</strong> 
                </li>
                <li>1회 대여 후 90분 이상 사용자에게 시간에 따라 추가 사용료가 발생합니다.</li>
            </ul>
            <span class="badge rounded-pill bg-warning text-dark table-scroll-guide">좌우로 스크롤 해보세요.</span>
            <div class="content-body__table">
                <table id="table-03" class="content-table content-table--center">
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th colspan="2">정(유료)회원</th>
                            <th>준(일반)회원</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowspan="4">기본 사용료<br />(90분 이내)</td>
                            <td>연(12월) 회원</td>
                            <td>30,000원</td>
                            <td rowspan="4">1일 이용권 :1,000원</td>
                        </tr>
                        <tr>
                            <td>반기(6월) 회원</td>
                            <td class="border-draw">20,000원</td>
                        </tr>
                        <tr>
                            <td>월(30일) 회원</td>
                            <td class="border-draw">5,000원</td>
                        </tr>
                        <tr>
                            <td>주(7일) 회원</td>
                            <td class="border-draw">2,500원</td>
                        </tr>	
                        <tr>
                            <td>요금결제수단</td>
                            <td colspan="4">휴대폰소액결제, 신용카드 등</td>
                            <!-- <td class="t_end">휴대폰소액결제, 신용카드 등</td> -->
                        </tr>
                        <tr>
                            <td>결제장소</td>
                            <td colspan="4">어울링 앱</td>
                            <!-- <td class="t_end">어울링 앱</td> -->
                        </tr>
                        <tr>
                            <td>추가요금</td>
                            <td colspan="2">90분 이후 30분당 500원</td>
                            <td class="t_end">90분 이후 30분당 1,000원</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="content-body">
            <h2 class="mb-3">환불정책</h2>
            <span class="badge rounded-pill bg-warning text-dark table-scroll-guide"> 좌우로 스크롤 해보세요.</span>
            <div class="content-body__table">
                <table id="table-04" class="content-table content-table--center">
                    <thead>
                        <tr>
                            <th colspan="2" rowspan="2">서비스 구분 <br/>(결제금액)</th>
                            <th colspan="3">서비스해지 요청시 환불 요금</th>
                        </tr>
                        <tr>
                            <th>결제 7일 이하</th>
                            <th>결제 14일 이하</th>
                            <th>결제 14일 초과</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowspan="2" width="15%">연회원<br/>(30,000원)</td>
                            <td width="10%">이용</td>
                            <td width="27%">27,000원<br/>기본요금(2,500원)+수수료(500원) 공제</td>
                            <td width="30%">24,000원<br/>기본요금(5,000원)+수수료(1,000원) 공제</td>
                            
                            <td rowspan="4">환불 요청일까지의 <br>월별 이용요금 공제<br>(사용월수 x 월회원권 이용요금)<br>+ 수수료(1,000원) 공제</td>
                        </tr>
                        <tr>
                            <td>비이용</td>
                            <td colspan="2" class="border-draw">30,000원</td>
                        </tr>
                        
                        <tr>
                            <td rowspan="2">반기회원<br/>(20,000원)</td>
                            <td>이용</td>
                            <td>17,000원<br/>기본요금(2,500원)+수수료(500원) 공제</td>
                            <td class="border-draw">14,000원<br/>기본요금(5,000원)+수수료(1,000원) 공제</td>
                        </tr>
                        <tr>
                            <td>비이용</td>
                            <td colspan="2" class="border-draw">20,000원</td>
                        </tr>
                        
                        <tr>
                            <td rowspan="2">월회원<br/>(5,000원)</td>
                            <td>이용</td>
                            <td>2,000원<br/>기본요금(2,500원)+수수료(500원) 공제</td>
                            <td>환불불가</td>
                            <td rowspan="6">환불 불가</td>
                        </tr>
                        <tr>
                            <td>비이용</td>
                            <td>5,000원</td>
                            <td class="border-draw">환불불가</td>
                        </tr>					
                        <tr>
                            <td rowspan="2">주회원<br/>(2,500원)</td>
                            <td>이용</td>
                            <td colspan="2" class="border-draw">환불불가</td>
                        </tr>
                        <tr>
                            <td>비이용</td>
                            <td>2,500원</td>
                            <td class="border-draw">환불불가</td>
                        </tr>	
                        <tr>
                            <td rowspan="2">일반(준회원)<br/>1일 이용권(1,000원)</td>
                            <td>이용</td>
                            <td rowspan="2" colspan="2" class="border-draw">환불불가</td>
                        </tr>
                        <tr>
                            <td class="border-draw">비이용</td>
                        </tr>	
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
<style lang="scss" scoped>
    .content-body__table {
        width: 100%; overflow:auto;
    }
    .table-scroll-guide {
        display: none;
    }
@include media(mobile) {
    $table-min-width: 768px;
    #table-03 {
        width: $table-min-width;
    }
    #table-04 {
        width: $table-min-width;
    }
    .table-scroll-guide {
        display: inline-block;
        font-size: 1.4rem;
    }
}
</style>